
import { defineComponent, ref } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { useStore } from "vuex";
import { useRouter, useRoute } from "vue-router";
import * as Yup from "yup";
import { Actions } from "@/store/enums/StoreEnums";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { useI18n } from "vue-i18n";
import { translate } from "@/hooks/TranslateService";

export default defineComponent({
  name: "password-reset-email",
  components: {
    Field,
    Form,
    ErrorMessage,
  },
  setup() {
    const { t, te } = useI18n();
    const store = useStore();
    const router = useRouter();
    const route = useRoute();

    document.title = translate("RESET_PASSWORD_PAGE_TITLE")

    const submitButton = ref<HTMLElement | null>(null);
      const successMessageContent = ref<string>(
      translate("PASSWORD_SUCCESS_UPDATED_MESSAGE")
    );

    //Create form validation object
    const forgotPassword = Yup.object().shape({
      password: 
      Yup.string()
      .min(6, translate("PASSWORD_LENGTH_VALIDATION"))
      .required(translate("PASSWORD_REQUIRED"))
      .label("Password")
    });

    //Form submit function
    const onSubmitForgotPassword = (values) => {
      // Activate loading indicator
      submitButton.value?.setAttribute("data-kt-indicator", "on");

      // dummy delay
      setTimeout(() => {
        // Send login request
        store
          .dispatch(Actions.RESET_PASSWORD, {
            ...values,
            id: route.params.user_id,
            hash: route.params.user_hash,
          })
          .then(() => {
            Swal.fire({
              text: successMessageContent.value,
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: "Ok",
              customClass: {
                confirmButton: "btn fw-bold btn-light-primary",
              },
            }).then(function () {
              // Go to page after successfully login
              router.push({ name: "sign-in" });
            });
          })
          .catch(() => {
            // Alert then login failed
            Swal.fire({
              text: store.getters.getErrors[0],
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Try again!",
              customClass: {
                confirmButton: "btn fw-bold btn-dark-blue",
              },
            });
          });

        submitButton.value?.removeAttribute("data-kt-indicator");
      }, 2000);
    };

    return {
      onSubmitForgotPassword,
      forgotPassword,
      submitButton,
      translate,
    };
  },
});
